<template>
  <span>
    <v-chip
      v-for="status in productStatus"
      :key="status"
      :color="'bg-light-' + getStatusColor(status)"
      :class="'mx-2 text-' + getStatusColor(status)"
      v-b-tooltip.hover
      :title="getStatusName(status)"
    >
      {{ getCustomerCountByStatus(status) }}
    </v-chip>
  </span>
</template>

<script>
import i18nDiclinaService from "../../core/services/diclina_i18n";

export default {
  name: "StatusProductChips",
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      productStatus: ["open", "opened", "accepted", "active", "rejected"]
    };
  },
  computed: {
    amountHouseHolds() {
      return (
        this.location.info.amountOfDwellingUnits +
        this.location.info.amountOfBusinessUnits
      );
    }
  },
  methods: {
    getCustomerCountByStatus(status) {
      const customers = this.location.customers || [];

      if (status === "open") {
        return this.amountHouseHolds - customers.length;
      } else {
        return customers.filter(item => {
          return item.status.product === status;
        }).length;
      }
    },
    getStatusColor(status) {
      return i18nDiclinaService.getDiclinaStatusStyle("product", status)?.color || "";
    },
    getStatusName(status) {
      return i18nDiclinaService.getDiclinaStatusName("product", status);
    }
  }
};
</script>

<style scoped></style>
