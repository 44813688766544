<template>
  <v-chip
    :close="close"
    :color="'bg-light-' + statusColor"
    :class="'text-' + statusColor"
    @click:close="emitClickClose"
  >
    <v-avatar v-if="marker" left class="mr-3">
      <v-img :src="'/media/marker/Marker_' + statusColor + '.png'"></v-img>
    </v-avatar>
    {{ statusName }}
  </v-chip>
</template>

<script>
import i18nDiclinaService from "../../core/services/diclina_i18n";

export default {
  name: "StatusChip",
  props: {
    statusGroup: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    close: {
      type: Boolean,
      default: false,
      required: false
    },
    marker: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    statusName() {
      return i18nDiclinaService.getDiclinaStatusName(
        this.statusGroup,
        this.status.replace("status_", "")
      );
    },
    statusColor() {
      return i18nDiclinaService.getDiclinaStatusStyle(
        this.statusGroup,
        this.status.replace("status_", "")
      )?.color || "";
    }
  },
  methods: {
    emitClickClose() {
      this.$emit("click:close");
    }
  }
};
</script>

<style scoped></style>
