<template>
  <div>
    <v-autocomplete
      v-model="content"
      :clearable="clearable"
      :items="housingIndustries"
      :multiple="multiple"
      :label="label"
      :item-value="itemValue"
      v-on="$listeners"
      @change="handleInput"
      :filter="housingFilter"
    >
      <template v-slot:selection="data">
        <v-chip close @click:close="remove(data.item.id)">
          <span v-if="data.item">{{ data.item.label }}</span>
          <span v-else>Keine Wohnungswirtschaft</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <span v-if="data.item">{{ data.item.label }}</span>
        <span v-else>Keine Wohnungswirtschaft</span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "HousingIndustryAutoComplete",
  props: {
    value: [Array, Number],
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Wohnungswirtschaft"
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  computed: {
    housingIndustries() {
      let housingIndustries = this.$store.getters.housingIndustries;
      housingIndustries.unshift({
        id: null,
        label: "Nicht Zugewiesen"
      });

      return housingIndustries;
    }
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    remove(item) {
      if (this.multiple) {
        const index = this.content.indexOf(item ? parseInt(item) : null);
        if (index >= 0) this.content.splice(index, 1);
      } else {
        this.content = null;
      }

      this.$emit("change");
    },

    itemValue(item) {
      return item.id ? parseInt(item.id) : null;
    },

    housingFilter(item, queryText) {
      const itemLabel = item.label.toLowerCase();
      const searchText = queryText.toLowerCase();
      return itemLabel.includes(searchText);
    }
  },
  
  created() {
    this.$store.dispatch("updateHousingIndustries");
  },
 
};
</script>

<style scoped></style>
