<template>
  <div>
    <v-autocomplete
      v-model="content"
      :items="items"
      :filter="
        (item, queryText, itemText) =>
          autocompleteStatusFilter(item, queryText, itemText, statusGroup)
      "
      :multiple="multiple"
      :label="itemLabel"
      v-on="$listeners"
      clearable
      @change="handleInput"
    >
      <template v-slot:selection="data">
        <status-chip
          :status="data.item"
          :status-group="statusGroup.value.key"
          :close="multiple"
          @click:close="remove(data.item)"
        />
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar size="sm" class="mr-3">
          <v-img
            :src="
              '/media/marker/Marker_' +
                getStatusColor(statusGroup.value.key, data.item) +
                '.png'
            "
          />
        </v-list-item-avatar>
        <v-list-item-content
          v-text="getStatusName(statusGroup.value.key, data.item)"
        />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import i18nDiclinaService from "../../core/services/diclina_i18n";
import StatusChip from "./StatusChip";

export default {
  name: "StatusAutoComplete",
  components: { StatusChip },
  props: {
    value: [Array, String],
    statusGroup: Object,
    multiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  computed: {
    itemLabel() {
      return (
        this.label ??
        this.getStatusGroupName(this.statusGroup.value.key) + " Status"
      );
    },

    items() {
      let keys = Object.keys(this.statusGroup.value.statusGroup.i18n.de_DE);

      for (let index in keys) {
        keys[index] = keys[index].replace("status_", "");
      }

      return keys;
    }
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    getStatusGroupName(statusGroup) {
      return i18nDiclinaService.getDiclinaStatusGroupName(statusGroup);
    },

    getStatusName(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusName(
        statusGroup,
        status.replace("status_", "")
      );
    },

    getStatusColor(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusStyle(
        statusGroup,
        status.replace("status_", "")
      )?.color || "";
    },

    autocompleteStatusFilter(item, queryText, itemText, statusGroup) {
      return this.getStatusName(statusGroup.value.key, item)
        .toLowerCase()
        .includes(queryText.toLowerCase());
    },

    remove(item) {
      if (this.multiple) {
        const index = this.content.indexOf(item);
        if (index >= 0) this.content.splice(index, 1);
      } else {
        this.content = null;
      }

      this.$emit("change");
    }
  }
};
</script>

<style scoped></style>
