<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="1100"
    :persistent="loading"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" @click="step = 1">
        <v-icon>mdi-table-edit</v-icon>
        <span class="ml-2">{{ title }}</span>
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-card-text class="py-8">
          <h4 class="mb-10">
            Massenverarbeitung von
            <span class="h2">{{ addresses.length }}</span>
            Adressen
          </h4>
          <v-stepper v-model="step" alt-labels>
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1" color="bg-primary">
                <h4>Eingabe</h4>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2" color="bg-primary">
                <h4>Überprüfung</h4>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3" color="bg-primary">
                <h4>Speichern</h4>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="text-center mx-auto" style="max-width: 500px">
                  <h4>Was soll geändert werden?</h4>
                  <div class="my-4">
                    <span class="font-size-lg align-middle">
                      Soll ein Feld nicht geändert werden, lassen Sie das Feld
                      leer.
                    </span>
                  </div>
                  <sales-person-auto-complete
                    v-if="can('ROLE_DSN_WRITE_SALES_PERSON')"
                    v-model="salesPerson"
                  />
                  <div v-if="can('ROLE_DSN_WRITE_STATUS')">
                    <status-auto-complete
                      v-for="statusGroup in statusGroups"
                      :key="statusGroup.value.key"
                      :status-group="statusGroup"
                      v-model="status[statusGroup.value.key]"
                    />
                  </div>
                  <div v-if="!can('ROLE_DSN_WRITE_SALES_PERSON') && !can('ROLE_DSN_WRITE_STATUS')">
                    Keine Felder änderbar
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2">
                <div class="text-center">
                  <h4>Ist das so richtig?</h4>
                  <div class="my-4">
                    <span class="font-size-lg align-middle">
                      Bitte gehen Sie sicher, dass alle Änderungen so korrekt
                      sind.
                    </span>
                  </div>
                  <v-data-table
                    :headers="headers"
                    :items="addresses"
                    :page.sync="tableControls.pagination.currentPage"
                    :items-per-page="tableControls.pagination.itemsPerPage"
                    @page-count="tableControls.pagination.pageCount = $event"
                    class="text-left"
                    hide-default-footer
                  >
                    <template v-slot:item.street="{ item }">
                      {{ item.address.street }} {{ item.address.houseNumber }}
                    </template>
                    <template v-slot:item.salesPerson="{ item }">
                      <sales-person-avatar
                        :sales-person="getSalesPerson(item.address.personId)"
                        :size="30"
                        color="secondary"
                      />
                      {{ getSalesPersonName(item.address.personId) }}
                    </template>
                    <template
                      v-for="(statusGroup, index) in statusGroups"
                      v-slot:[getStatusGroupSlotName(statusGroup.value.key)]="{
                        item
                      }"
                    >
                      <status-chip
                          :key="index"
                          :status="
                        getStatus(
                          statusGroup.value.key,
                          item.status[statusGroup.value.key]
                        )
                      "
                          :status-group="statusGroup.value.key"
                      />
                    </template>

                    <template v-slot:footer>
                      <data-table-footer-controls
                        :item-count="addresses.length"
                        v-model="tableControls"
                      />
                    </template>
                  </v-data-table>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3">
                <div class="text-center">
                  <h4>Sollen die Änderungen übernommen werden?</h4>
                  <div class="my-4">
                    <span class="font-size-lg align-middle">
                      Sobald Sie auf
                      <span class="font-weight-bolder">SPEICHERN</span> klicken
                      werden Ihre Änderungen festgeschrieben.
                    </span>
                  </div>
                  <div class="py-7">
                    <b-btn
                      class="v-btn"
                      variant="primary"
                      @click="save"
                      :disabled="loading"
                    >
                      <v-icon v-show="!loading">mdi-check</v-icon>
                      <v-progress-circular
                        v-show="loading"
                        indeterminate
                        class="bg-primary mr-3"
                        size="24"
                        width="3"
                      />
                      <b>Speichern</b>
                    </b-btn>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions class="justify-content-end">
          <b-btn
            variant="outline-secondary"
            :disabled="loading"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
            Abbrechen
          </b-btn>
          <b-btn
            v-show="step > 1"
            class="ml-3"
            variant="outline-secondary"
            :disabled="loading"
            @click="step = step - 1"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Zurück
          </b-btn>
          <b-btn
            v-show="step < lastStep"
            class="ml-3"
            variant="primary"
            @click="step = step + 1"
          >
            <v-icon>mdi-arrow-right</v-icon>
            Weiter
          </b-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import SalesPersonAutoComplete from "./SalesPersonAutoComplete";
import StatusAutoComplete from "./StatusAutoComplete";
import SalesPersonAvatar from "./SalesPersonAvatar";
import StatusChip from "./StatusChip";
import DataTableFooterControls from "./DataTableFooterControls";
import i18nDiclinaService from "../../core/services/diclina_i18n";
export default {
  name: "AddressBulkEditModal",
  components: {
    DataTableFooterControls,
    StatusChip,
    SalesPersonAvatar,
    StatusAutoComplete,
    SalesPersonAutoComplete
  },

  props: {
    title: {
      type: String,
      default: "Auswahl bearbeiten"
    },
    addresses: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      step: 1,
      lastStep: 3,
      dialog: false,
      salesPerson: null,
      status: {},
      loading: false,
      tableControls: {
        pagination: {
          itemsPerPage: 10,
          currentPage: 1,
          pageCount: 0,
          itemsPerPageOptions: [10, 15, 25, 50, 100]
        }
      }
    };
  },

  computed: {
    statusGroups() {
      return this.$store.getters.statusGroups.filter(statusGroup => {
        return statusGroup.value.key !== "product" && this.getStatusGroupWritePermission(statusGroup);
      });
    },

    headers() {
      let headers = [{ text: "Straße + Hausnr.", value: "street" }];

      if (this.$authorizationService.can("ROLE_DSN_WRITE_SALES_PERSON")) {
        headers.push({ text: "Verantwortlicher", value: "salesPerson" });
      }

      if (this.$authorizationService.can("ROLE_DSN_WRITE_STATUS")) {
        const statusGroups = this.statusGroups;
        for (const statusGroup of statusGroups) {
          headers.push({
            text: statusGroup.text,
            value: "status." + statusGroup.value.key
          });
        }
      }

      return headers;
    }
  },

  methods: {
    async save() {
      this.loading = true;
      const result = await this.$store.dispatch(
        "bulkUpdateAddresses",
        this.getUpdatedAddresses()
      );

      if (result) {
        this.dialog = false;
        this.$root.$emit("set-loading-map-view", true);
      }
      this.loading = false;
    },

    getSalesPerson(salesPersonId) {
      const id = this.getSalesPersonId(salesPersonId);

      return this.$store.getters.salesPersonById(id);
    },

    getSalesPersonName(salesPersonId) {
      const salesPerson = this.getSalesPerson(salesPersonId);
      return salesPerson.firstName + " " + salesPerson.lastName;
    },

    getStatus(statusGroup, status) {
      return this.status[statusGroup] || status;
    },

    getSalesPersonId(salesPersonId) {
      if (this.salesPerson || this.salesPerson === 0) {
        return this.salesPerson;
      } else {
        return salesPersonId;
      }
    },

    getStatusGroupSlotName(statusGroup) {
      return "item.status." + statusGroup;
    },
    getStatusGroupWritePermission(statusGroup) {
      return this.$authorizationService.can(i18nDiclinaService.getDiclinaEditPermission(statusGroup.value.key));
    },

    getUpdatedAddresses() {
      let addresses = [];

      for (let i = 0; i < this.addresses.length; i++) {
        const currAddress = JSON.parse(JSON.stringify(this.addresses[i]));
        let address = {
          address: {
            id: currAddress.address.id
          },
          status: {}
        };

        address.address.personId = this.getSalesPersonId(
          currAddress.address.personId
        );

        for (const statusGroup of Object.entries(this.statusGroups)) {
          address.status[statusGroup[1].value.key] = this.getStatus(
            statusGroup[1].value.key,
            currAddress.status[statusGroup[1].value.key]
          );
        }

        addresses.push(address);
      }

      return addresses;
    }
  }
};
</script>

<style scoped></style>
