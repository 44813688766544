<template>
  <div
    id="map_canvas"
    class="shadow-sm rounded-left-0"
    style="width:100%; height:80vh;"
  ></div>
</template>

<script>
import gmap from "@/core/services/gmap/gmap.service";
import ColorService from "@/core/services/colors/color.service";

import markerMapView from "../../../core/services/gmap/mapviews/marker";
import heatmapMapView from "../../../core/services/gmap/mapviews/heatmap";

const zoomThreshold = 17;

let google;

let viewportChangedTimeout = null;
window.curentZoom = 25;

const MAP_VIEW_HEATMAP = "heatmap";
const MAP_VIEW_MARKER = "marker";

export default {
  name: "googleMap",
  props: ["headers"],
  data() {
    return {
      map: null,
      drawingManager: null,
      directionsService: null,
      heatmaps: [],
      activatedMapView: MAP_VIEW_MARKER
    };
  },
  computed: {
    mapView() {
      let mapView;
      switch (this.activatedMapView) {
        case MAP_VIEW_HEATMAP:
          mapView = heatmapMapView;
          break;
        default:
          mapView = markerMapView;
      }

      mapView.setGoogle(google);
      mapView.setMap(this.map);
      mapView.setHeader(this.headers);

      return mapView;
    }
  },
  asyncComputed: {
    locations: {
      async get() {
        return await this.$store.getters.addresses();
      },
      default: []
    }
  },
  methods: {
    startRefreshMap() {
      this.$emit("loadingMapView", true);
    },

    refreshMap() {
      this.toggleMarkerMapView();
    },

    async activateHeatmap() {
      await this.mapView.resetMarker();

      this.activatedMapView = MAP_VIEW_HEATMAP;
      await this.startRefreshMap();
    },

    async deactivateHeatmap() {
      this.activatedMapView = MAP_VIEW_MARKER;
      await this.mapView.resetMarker();
    },

    async toggleMarkerMapView() {
      const mapView = this.mapView;

      await mapView.resetMarker();
      await mapView.initMarker();

      if (this.activatedMapView === MAP_VIEW_HEATMAP) {
        this.$root.$emit("createdHeatmapMapView", mapView);
      }
      this.$emit("loadingMapView", false);
    },

    changeGradient(heatmap, startColor, endColor) {
      heatmap.set(
        "gradient",
        ColorService.createGradient(startColor, endColor, 14)
      );
    },

    createDrawingTools(selectedTool) {
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }

      switch (selectedTool) {
        case "polygon":
        case "circle":
          this.initDrawingTool();
          break;
      }
    },

    initDrawingTool() {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        markerOptions: {
          draggable: true
        },
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            // google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON
            // google.maps.drawing.OverlayType.POLYLINE,
            // google.maps.drawing.OverlayType.RECTANGLE,
          ]
        }
      });

      this.drawingManager.setMap(this.map);

      const rootInstance = this.$root;
      const drawingManager = this.drawingManager;

      google.maps.event.addListener(
        this.drawingManager,
        "polygoncomplete",
        function(polygon) {
          rootInstance.$emit("addedPolygon", polygon);
          drawingManager.setDrawingMode(null);
        }
      );
    },

    initDirectionService() {
      this.directionsService = new google.maps.DirectionsService();

      const directionService = this.directionsService;

      this.$root.$emit("createdDirectionService", directionService);
    },

    drawNewPolygon() {
      this.drawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON
      );
    },

    drawNewCircle(data) {
      // this.drawingManager.setDrawingMode(
      //   google.maps.drawing.OverlayType.CIRCLE
      // );

      const map = this.map;

      const circle = new google.maps.Circle({
        // strokeColor: "#FF0000",
        // strokeOpacity: 0.8,
        // strokeWeight: 2,
        // fillColor: "#FF0000",
        // fillOpacity: 0.35,
        map,
        center: new google.maps.LatLng(
          data.centerAddress.point.lat,
          data.centerAddress.point.lng
        ),
        radius: data.radius
      });

      this.$root.$emit("addedCircle", circle);
    },

    generateNewRouteSearch(coordinates) {
      const polygon = new google.maps.Polygon({
        paths: coordinates,
        map: this.map
      });

      this.$root.$emit("addedRouteSearch", polygon);
    },

    abortDrawingNewPolygon() {
      this.drawingManager.setDrawingMode(null);
    },

    abortDrawingNewCirlce() {
      this.drawingManager.setDrawingMode(null);
    }
  },
  async mounted() {
    try {
      google = await gmap.init();
      const geocoder = new google.maps.Geocoder();
      this.map = new google.maps.Map(this.$el, gmap.mapOptions);
      let map = this.map;
      this.zoom = this.map.getZoom();

      geocoder.geocode(
        { address: process.env.VUE_APP_MAPS_DEFAULT_LOCATION },
        (results, status) => {
          if (status !== "OK" || !results[0]) {
            throw new Error(status);
          }

          map.setCenter(results[0].geometry.location);
          map.fitBounds(results[0].geometry.viewport);
        }
      );

      const startRefreshMap = this.startRefreshMap;

      this.map.addListener("zoom_changed", function() {
        const zoom = map.getZoom();
        if (
          (zoom > zoomThreshold && window.currentZoom <= zoomThreshold) ||
          (zoom <= zoomThreshold && window.currentZoom > zoomThreshold)
        ) {
          clearTimeout(viewportChangedTimeout);

          viewportChangedTimeout = setTimeout(() => {
            startRefreshMap();
          }, 500);
        }

        window.currentZoom = zoom;
      });

      // this.map.addListener("center_changed", function() {
      //   const zoom = map.getZoom();
      //   if (zoom > 16) {
      //     clearTimeout(viewportChangedTimeout);
      //
      //     viewportChangedTimeout = setTimeout(() => {
      //       startRefreshMap();
      //     }, 1000);
      //   }
      // });
    } catch (error) {
      // console.error(error);
    }
  },
  created() {
    this.$root.$on("toolChanged", this.createDrawingTools);
    this.$root.$on("drawNewPolygon", this.drawNewPolygon);
    this.$root.$on("drawNewCircle", this.drawNewCircle);
    this.$root.$on("generateNewRouteSearch", this.generateNewRouteSearch);
    this.$root.$on("abortDrawingNewPolygon", this.abortDrawingNewPolygon);
    this.$root.$on("initDirectionService", this.initDirectionService);
    this.$root.$on("activateHeatmap", this.activateHeatmap);
    this.$root.$on("deactivateHeatmap", this.deactivateHeatmap);
  },
  destroyed() {
    this.mapView.resetMarker();
  }
};
</script>

<style lang="scss"></style>
