<template>
  <div class="pb-8">
    <address-auto-complete v-model="startAddress" label="Start-Punkt" />
    <div class="my-10" v-if="startAndEndNotNull">
      <v-row
        v-for="(interimDestination, index) in interimDestinations"
        :key="interimDestination.key"
        no-gutters
        class="my-5"
      >
        <v-col>
          <address-auto-complete
            v-model="interimDestination.address"
            :label="'Zwischenstop ' + (index + 1)"
          />
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-btn
            icon
            class="my-auto"
            @click="removeInterimDestination(interimDestination)"
          >
            <v-icon class="text-danger">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn
        text
        block
        class="mt-10"
        @click="addInterimsDestination"
        :disabled="!canAddNewInterimDestination"
      >
        <v-icon>mdi-plus</v-icon>
        Zwischenstop hinzufügen
      </v-btn>
    </div>
    <address-auto-complete
      v-model="endAddress"
      label="End-Punkt"
      class="mt-5"
    />
    <div class="mt-10">
      <span class="font-weight-bold" style="font-size: 1.3rem">
        {{ radius }}
      </span>
      <span class="text-muted" style="font-size: 1.1rem">
        Meter Abweichung entlang der Route
      </span>
      <v-slider
        :min="25"
        :max="100"
        :step="5"
        color="bg-primary"
        v-model="radius"
      />
    </div>
    <b-btn class="v-btn" :disabled="!canSearchRoute" @click="generateLine">
      <v-icon>mdi-map-search</v-icon>
      Entlang der Route Suchen
    </b-btn>
  </div>
</template>

<script>
import AddressAutoComplete from "../../../../components/AddressAutoComplete";
import * as helpers from "@turf/helpers";
import buffer from "@turf/buffer";
import ApiService from "../../../../../core/services/api.service";
import simplify from "@turf/simplify";

export default {
  name: "ToolSettingsRoute",
  components: { AddressAutoComplete },
  data() {
    return {
      startAddress: null,
      endAddress: null,
      interimDestinations: [],
      radius: 25,
      interimDestinationCounter: 0,
      directionService: null,
      travelMode: "DRIVING",
      polygon: null,
      addresses: []
    };
  },
  computed: {
    startAndEndNotNull() {
      return this.startAddress && this.endAddress;
    },
    canAddNewInterimDestination() {
      for (const interimDestination of this.interimDestinations) {
        if (!interimDestination.address) {
          return false;
        }
      }

      return true;
    },
    canSearchRoute() {
      return this.startAndEndNotNull && this.canAddNewInterimDestination;
    },
    interimDestinationPoints() {
      let points = [];

      for (let i = 0; i < this.interimDestinations.length; i++) {
        points.push({
          location: this.interimDestinations[i].address.point,
          stopover: true
        });
      }

      return points;
    },
    addressIds() {
      let addressIds = [];
      const addresses = this.addresses;

      for (const address of addresses) {
        if (!addressIds.includes(address.address.id)) {
          addressIds.push(address.address.id);
        }
      }

      return addressIds;
    }
  },
  methods: {
    addInterimsDestination() {
      this.interimDestinations.push({
        address: null,
        key: this.interimDestinationCounter
      });

      this.interimDestinationCounter++;
    },
    removeInterimDestination(interimDestination) {
      this.interimDestinations = this.interimDestinations.filter(function(
        item
      ) {
        return item.address !== interimDestination.address;
      });
    },
    removePolygon() {
      if (this.polygon) {
        this.polygon.setMap(null);
      }
      this.polygon = null;
    },
    async generateLine() {
      this.removePolygon();
      const radius = this.radius;
      const rootInstance = this.$root;
      const thisInstance = this;

      const request = {
        origin: this.startAddress.point,
        destination: this.endAddress.point,
        waypoints: this.interimDestinationPoints,
        optimizeWaypoints: true,
        travelMode: this.travelMode
      };

      await this.directionService.route(request, async function(
        response,
        status
      ) {
        if (status === "OK") {
          let points = [];
          let route = response.routes[0];

          for (let i = 0; i < route.overview_path.length; i++) {
            points.push([
              route.overview_path[i].lat(),
              route.overview_path[i].lng()
            ]);
          }

          const lineString = helpers.lineString(points);

          const bufferObj = buffer(lineString, radius, {
            units: "meters"
          });

          const tolerance = 0.00005;

          const options = {
            tolerance,
            highQuality: true,
            mutate: true
          };
          const simplified = simplify(bufferObj, options);

          let coordinates = [];

          for (const point of simplified.geometry.coordinates[0]) {
            coordinates.push({
              lat: point[0],
              lng: point[1]
            });
          }

          // let count = 0;
          let addresses = [];

          await ApiService.post("addresses/by-route", {
            route: coordinates
          }).then(function(response) {
            // count = response.data.count;
            addresses = response.data.items;
          });

          thisInstance.addresses = addresses;

          rootInstance.$emit("generateNewRouteSearch", coordinates);
        } else {
          // console.error("route error");
        }
      });
    },
    addNewRouteSearch(polygon) {
      this.polygon = polygon;
      let route = [];

      polygon.getPaths().forEach(p => {
        p.getArray().forEach(pt => {
          route.push({ lat: pt.lat(), lng: pt.lng() });
        });
      });
    },
    initDirectionService(directionService) {
      this.directionService = directionService;
    }
  },
  watch: {
    addresses() {
      if (this.polygon === null) {
        this.$store.dispatch("setMapToolFilter", null);
      } else {
        this.$store.dispatch("setMapToolFilter", this.addressIds);
      }

      this.$emit("change", this.polygon !== null);
    }
  },
  created() {
    this.$root.$on("addedRouteSearch", this.addNewRouteSearch);
    this.$root.$on("createdDirectionService", this.initDirectionService);
    this.$root.$emit("initDirectionService");
  },
  beforeDestroy() {
    this.removePolygon();
  }
};
</script>

<style scoped></style>
