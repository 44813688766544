<template>
  <div>
    <v-autocomplete
      v-model="content"
      clearable
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      :filter="autocompleteAddressFilter"
      :itemValue="itemValue"
      hide-details
      :label="label"
      v-on="$listeners"
      @change="handleInput"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Geben Sie eine <strong>Straße</strong> oder eine
            <strong>PLZ</strong> ein.
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:selection="data">
        <address-display :address="data.item" :zip-code="false" />
      </template>

      <template v-slot:item="data">
        <address-display :address="data.item" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import AddressDisplay from "./AddressDisplay";
export default {
  name: "AddressAutoComplete",
  components: { AddressDisplay },
  props: {
    value: Object,
    label: String
  },
  data() {
    return {
      content: this.value,
      loading: false,
      search: null,
      searchTimeout: null,
      items: []
    };
  },
  watch: {
    search(val) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        val && val !== this.content && this.querySelections(val);
        this.loading = true;
      }, 500); // 0.5 sec delay
    }
  },
  methods: {
    async querySelections(search) {
      this.items = await this.$store.getters.searchAddresses(search);
      this.loading = false;
    },

    autocompleteAddressFilter(item, queryText) {
      const address = item;

      return (
        address.address.street
          .toLowerCase()
          .includes(queryText.toLowerCase()) ||
        address.address.zipCode.toString().includes(queryText.toLowerCase())
      );
    },

    itemValue(item) {
      return item;
    },

    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style scoped></style>
