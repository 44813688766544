<template>
  <v-row class="px-6 pt-4 border-top" justify="center">
    <v-col cols="auto" align-self="center">
      <span class="font-weight-bold font-size-lg">
        {{ itemCount }}
      </span>
      Gefundene Datensätze
    </v-col>
    <v-spacer />
    <v-col cols="auto" align-self="center">
      <v-select
        v-model="tableControls.pagination.itemsPerPage"
        :items="tableControls.pagination.itemsPerPageOptions"
        hide-details
        @change="emitInput"
        solo
        dense
        style="max-width: 160px"
      >
        <template v-slot:append>
          <span class="font-size-sm">Zeilen pro Seite</span>
        </template>
      </v-select>
    </v-col>
    <v-col cols="auto" align-self="center">
      <v-pagination
        v-model="tableControls.pagination.currentPage"
        :length="tableControls.pagination.pageCount"
        total-visible="7"
        color="bg-primary"
        @change="emitInput"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DataTableFooterControls",
  props: {
    itemCount: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      default: () => {
        return {
          itemsPerPage: 25,
          currentPage: 1,
          pageCount: 0,
          itemsPerPageOptions: [25, 50, 100, 200, 500]
        };
      }
    }
  },
  data() {
    return {
      tableControls: this.value
    };
  },
  methods: {
    emitInput() {
      this.$emit("input", this.tableControls);
    },
  }
};
</script>

<style scoped></style>
