import { render, staticRenderFns } from "./ToolSettingsPolygon.vue?vue&type=template&id=13f9868c&scoped=true&"
import script from "./ToolSettingsPolygon.vue?vue&type=script&lang=js&"
export * from "./ToolSettingsPolygon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f9868c",
  null
  
)

export default component.exports