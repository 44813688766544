<template>
  <div>
    <v-autocomplete
      v-model="content"
      :clearable="clearable"
      :items="salesPersons"
      :multiple="multiple"
      :label="label"
      :item-value="itemValue"
      v-on="$listeners"
      @change="handleInput"
      :filter="salesPersonFilter"
    >
      <template v-slot:selection="data">
        <sales-person-chip
          :sales-person="data.item"
          :close="multiple"
          avatar
          @click:close="remove(data.item.id)"
        />
      </template>
      <template v-slot:item="data">
        <sales-person-avatar
          :sales-person="data.item"
          left
          color="secondary"
          :size="30"
        />

        {{ data.item.firstName }} {{ data.item.lastName }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import SalesPersonChip from "./SalesPersonChip";
import SalesPersonAvatar from "./SalesPersonAvatar";

export default {
  name: "SalesPersonAutoComplete",
  components: { SalesPersonAvatar, SalesPersonChip },
  props: {
    value: [Array, Number],
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Verantwortlicher"
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  computed: {
    salesPersons() {
      return this.$store.getters.salesPersons;
    }
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    remove(item) {
      if (this.multiple) {
        const index = this.content.indexOf(parseInt(item));
        if (index >= 0) this.content.splice(index, 1);
      } else {
        this.content = null;
      }

      this.$emit("change");
    },
    itemValue(item) {
      return parseInt(item.id);
    },
    //sales person filter
    salesPersonFilter(item, queryText) {
      const lowerCaseQueryText = queryText.toLowerCase();
      const salesPersonName = `${item.firstName} ${item.lastName}`.toLowerCase();
      return salesPersonName.startsWith(lowerCaseQueryText);
    }
  }
};
</script>

<style scoped></style>
