<template>
  <v-chip :close="close" @click:close="emitClickClose">
    <sales-person-avatar :sales-person="salesPerson" left color="white" />
    {{ salesPersonName }}
  </v-chip>
</template>

<script>
import SalesPersonAvatar from "./SalesPersonAvatar";
export default {
  name: "SalesPersonChip",
  components: { SalesPersonAvatar },
  props: {
    salesPerson: {
      type: Object,
      required: true
    },
    avatar: {
      type: Boolean,
      default: false,
      required: false
    },
    close: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    salesPersonName() {
      return this.salesPerson.firstName + " " + this.salesPerson.lastName;
    }
  },
  methods: {
    emitClickClose() {
      this.$emit("click:close");
    }
  }
};
</script>

<style scoped></style>
