<template>
  <div>
    <address-auto-complete v-model="centerAddress" label="Mittelpunkt" />
    <div class="my-5">
      <span class="font-weight-bold" style="font-size: 1.3rem">
        {{ radius }}
      </span>
      <span class="text-muted" style="font-size: 1.1rem">Meter Radius</span>
      <v-slider
        :min="25"
        :max="1000"
        :step="5"
        color="bg-primary"
        v-model="radius"
      />
      <b-btn :disabled="loadingNewCircle" @click="generateCircle" class="v-btn">
        <v-icon>mdi-shape-circle-plus</v-icon>Kreis generieren
      </b-btn>
    </div>

    <map-tool-selection-list
      :items="circles"
      :loading="loadingNewCircle"
      v-model="activeCircleIndex"
      @change="viewCircle"
      @remove="removeCircle"
    >
      <template #list-display="item">
        <span style="font-size: 1rem">
          {{ item.selection.centerAddress.address.street }}
          {{ item.selection.centerAddress.address.houseNumber }}
        </span>
        <br />
        <span class="text-muted">
          {{ item.selection.circle.radius }}m Radius
        </span>
      </template>
    </map-tool-selection-list>
  </div>
</template>

<script>
import AddressAutoComplete from "../../../../components/AddressAutoComplete";
import ApiService from "../../../../../core/services/api.service";
import MapToolSelectionList from "../../../../components/MapToolSelectionList";
export default {
  name: "ToolSettingsPerimeter",
  components: { MapToolSelectionList, AddressAutoComplete },
  data() {
    return {
      centerAddress: null,
      radius: 25,
      circles: [],
      activeCircleIndex: null,
      loadingNewCircle: false
    };
  },
  computed: {
    activeCircle() {
      return this.circles[this.activeCircleIndex];
    },
    addressIds() {
      let addressIds = [];

      for (let i = 0, l = this.circles.length; i < l; i++) {
        const addresses = this.circles[i].addresses;

        for (const address of addresses) {
          if (!addressIds.includes(address.address.id)) {
            addressIds.push(address.address.id);
          }
        }
      }

      return addressIds;
    }
  },
  methods: {
    generateCircle() {
      this.$root.$emit("drawNewCircle", {
        centerAddress: this.centerAddress,
        radius: this.radius
      });
    },

    async addCircle(circle) {
      this.loadingNewCircle = true;

      let count = 0;
      let addresses = [];
      const centerAddress = this.centerAddress;

      await ApiService.post("addresses/by-radius", {
        lat: circle.center.lat(),
        lng: circle.center.lng(),
        r: circle.radius / 1000
      }).then(function(response) {
        count = response.data.count;
        addresses = response.data.items;
      });

      this.circles.push({ count, circle, addresses, centerAddress });
      this.loadingNewCircle = false;
    },

    removeAllCircles() {
      for (let i = 0, l = this.circles.length; i < l; i++) {
        const circle = this.circles[i].circle;

        circle.setMap(null);
      }

      this.$store.dispatch("setMapToolFilter", null);
    },

    removeCircle(circle) {
      circle.circle.setMap(null);
      this.circles.splice(this.circles.indexOf(circle), 1);
    },

    viewCircle() {
      for (let i = 0, l = this.circles.length; i < l; i++) {
        const circle = this.circles[i].circle;

        circle.setOptions({
          fillColor: "#000000",
          strokeColor: "#000000",
          editable: false
        });
      }

      const activeCircle = this.activeCircle;

      if (activeCircle) {
        activeCircle.circle.setOptions({
          fillColor: "#0000ff",
          strokeColor: "#0000ff",
          editable: false
        });
      }
    }
  },
  watch: {
    addressIds() {
      if (this.circles.length === 0) {
        this.$store.dispatch("setMapToolFilter", null);
      } else {
        this.$store.dispatch("setMapToolFilter", this.addressIds);
      }

      this.$emit("change", this.circles.length > 0);
    }
  },
  created() {
    this.$root.$on("addedCircle", this.addCircle);
  },
  beforeDestroy() {
    this.removeAllCircles();
  }
};
</script>

<style scoped></style>
