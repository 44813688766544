import store from "./store/index";

const AddressFilterService = {
  filterAddress: address => {
    return matchesFilter(address);
  }
};

const matchesFilter = location => {
  const filter = store.getters.filter;

  return (
    matchesStatusFilter(filter, location) &&
    matchesSalesPersonsFilter(filter, location) &&
    matchesMapToolsFilter(filter, location) &&
    matchesDwellingUnitsFilter(filter, location) &&
    matchesHousingIndustryFilter(filter, location)
  );
};

const matchesStatusFilter = (filter, location) => {
  let statusFilter = filter.status;

  if (statusFilter) {
    let matchesProductFilter = true;

    for (const [key, statusGroup] of Object.entries(statusFilter)) {
      if (statusGroup.length > 0) {
        if (key === "product") {
          // product status, our special boi
          matchesProductFilter = matchesProductStatusFilter(
            statusGroup,
            location
          );
        } else {
          if (!statusGroup.includes(location.status[key])) {
            return false;
          }
        }
      }
    }

    return matchesProductFilter;
  } else {
    return false;
  }
};

const matchesProductStatusFilter = (productsStatus, location) => {
  if (productsStatus === "open") {
    if (location.customers) {
      if (location.customers.length === 0) {
        return true;
      } else {
        return (
          location.info.amountOfDwellingUnits +
            location.info.amountOfBusinessUnits >
          location.customers.length
        );
      }
    }

    return true;
  } else {
    if (location.customers) {
      for (let i = 0; i < location.customers.length; i++) {
        const customer = location.customers[i];

        if (customer.status.product === productsStatus) {
          return true;
        }
      }
    }

    return false;
  }
};

const matchesSalesPersonsFilter = (filter, location) => {
  let salesPersonFilter = filter.salesPersons;

  if (salesPersonFilter) {
    if (salesPersonFilter.length > 0) {
      if (!salesPersonFilter.includes(location.address.personId)) {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

const matchesMapToolsFilter = (filter, location) => {
  let mapToolsFilter = filter.mapTools;

  if (mapToolsFilter) {
    return mapToolsFilter.includes(location.address.id);
  } else {
    return true;
  }
};

const matchesDwellingUnitsFilter = (filter, location) => {
  let dwellingUnitsFilter = filter.dwellingUnits;

  if (dwellingUnitsFilter) {
    const dwellingUnitCount =
      location.info.amountOfBusinessUnits + location.info.amountOfDwellingUnits;

    return (
      dwellingUnitCount >= dwellingUnitsFilter.range[0] &&
      dwellingUnitCount <= dwellingUnitsFilter.range[1]
    );
  } else {
    return false;
  }
};

const matchesHousingIndustryFilter = (filter, location) => {
  let housingIndustryFilter = filter.housingIndustryFilter;

  if (housingIndustryFilter) {
    if (housingIndustryFilter.length > 0) {
      if (
        location.address.housingIndustry &&
        housingIndustryFilter.includes(
          parseInt(location.address.housingIndustry.id)
        )
      ) {
        return true;
      } else if (
        location.address.housingIndustry === null &&
        housingIndustryFilter.includes(null)
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

export default AddressFilterService;
