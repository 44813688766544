<template>
  <div>
    <span class="text-muted" style="font-size: 1.1rem">Von </span>
    <span class="font-weight-bold" style="font-size: 1.3rem">
      {{ content[0] }}
    </span>
    <span class="text-muted" style="font-size: 1.1rem">bis</span>
    <span class="font-weight-bold" style="font-size: 1.3rem">
      {{ content[1] }}
    </span>
    <span class="text-muted" style="font-size: 1.1rem">Einheiten</span>
    <v-range-slider
      v-model="content"
      :min="rangeLimits[0]"
      :max="rangeLimits[1]"
      color="bg-primary"
      v-on="$listeners"
      @change="handleInput"
    ></v-range-slider>
  </div>
</template>

<script>
export default {
  name: "DwellingUnitRangeSlider",
  props: {
    value: Array,
    rangeLimits: {
      type: Array,
      default: () => [0, 100]
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style scoped></style>
