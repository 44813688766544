<template>
  <div>
    <v-expansion-panels
      focusable
      accordion
      v-model="currentId"
      v-on="$listeners"
      @change="emitChange"
    >
      <v-expansion-panel v-for="(selection, index) in items" :key="index">
        <v-expansion-panel-header class="text-left">
          <v-row no-gutters>
            <v-col cols="auto">
              <slot name="list-display" :selection="selection">
                <span class="font-weight-bold" style="font-size: 1.2rem">
                  {{ selectionName }} {{ index + 1 }}
                </span>
              </slot>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="text--secondary pr-5">
              <span>
                {{
                  getSelectionInfos(selection).countAfterFilter.toLocaleString(
                    "de"
                  )
                }}
                /
                {{ selection.count.toLocaleString("de") }}
                Adressen
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="py-4">
          <v-row>
            <v-col>
              {{
                getSelectionInfos(selection).countAfterFilter.toLocaleString(
                  "de"
                )
              }}
              Adressen nach Filterung
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ selection.count.toLocaleString("de") }} Adressen Gesamt
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Straßen:
              {{
                Object.keys(getSelectionInfos(selection).streetNames)
                  .sort()
                  .join(", ")
              }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              PLZ:
              {{ getSelectionInfos(selection).zipCodes.join(", ") }}
            </v-col>
          </v-row>

          <v-btn block class="mt-6" @click.prevent="emitRemove(selection)">
            <v-icon class="text-danger mr-2">mdi-close</v-icon>
            {{ selectionName }} entfernen
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels accordion readonly :value="false">
      <v-expansion-panel v-show="loading">
        <v-expansion-panel-header class="text-left">
          <v-row no-gutters>
            <v-col cols="auto">
              <span class="font-weight-bold" style="font-size: 1.2rem">
                <v-skeleton-loader type="text" width="100" />
              </span>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="text--secondary pr-5">
              <v-skeleton-loader type="text" width="100" />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import AddressFilterService from "../../core/services/addressfilter.service";

export default {
  name: "MapToolSelectionList",
  props: {
    value: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    selectionName: {
      type: String,
      required: false,
      default: "Auswahl"
    }
  },
  data() {
    return {
      currentId: this.value
    };
  },
  methods: {
    getSelectionInfos(selection) {
      const addresses = selection.addresses;
      let countAfterFilter = 0;
      let streetNames = {};
      let zipCodes = [];

      for (const address of addresses) {
        const streetName = address.address.street;
        const zipCode = address.address.zipCode;

        if (typeof streetNames[streetName] === "undefined") {
          streetNames[streetName] = 0;
        }

        if (!zipCodes.includes(zipCode)) {
          zipCodes.push(zipCode);
        }

        streetNames[streetName] = streetNames[streetName] + 1;

        if (AddressFilterService.filterAddress(address)) {
          countAfterFilter++;
        }
      }

      return {
        countAfterFilter,
        streetNames,
        zipCodes
      };
    },

    emitChange(value) {
      this.$emit("input", value);
    },

    emitRemove(item) {
      this.$emit("remove", item);
    }
  }
};
</script>

<style scoped></style>
