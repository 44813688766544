<template>
  <div>
    {{ address.address.street }} {{ address.address.houseNumber }}
    <span v-if="zipCode" class="text-muted">
      , {{ address.address.zipCode }} {{ address.address.city }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AddressDisplay",
  props: {
    address: {
      type: Object,
      required: true
    },
    zipCode: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
