import store from "../../store/index";
import AddressFilterService from "../../addressfilter.service";

let google = null;
let map = null;
// eslint-disable-next-line no-unused-vars
let header = [];

let heatmapMarker = [];
let heatmap = null;

const heatmapMapView = {
  name: "heatmap",
  initMarker: async () => {
    const locations = await store.getters.addresses();

    if (google && locations) {
      locations.forEach(location => {
        if (AddressFilterService.filterAddress(location)) {
          store.dispatch("addVisibleAddress", location);

          const point = new google.maps.LatLng(
            location.point.lat,
            location.point.lng
          );

          // const weight =
          //   location.info.amountHouseholdsPriv + location.info.amountOfBusinessUnits;

          // heatmapMarker.push({ location: point, weight: weight });
          heatmapMarker.push(point);
        }
      });

      heatmap = new google.maps.visualization.HeatmapLayer({
        data: heatmapMarker,
        map: map,
        radius: 10
      });
    }
  },
  resetMarker: async () => {
    if (heatmap) {
      heatmap.setMap(null);
      heatmapMarker = [];
    }

    await store.dispatch("clearVisibleAddresses");
  },
  setGoogle: googleObj => (google = googleObj),
  setMap: mapObj => (map = mapObj),
  setHeader: headerObj => (header = headerObj)
};

export default heatmapMapView;
