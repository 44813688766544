<template>
  <div>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="auto">
          Adressdaten
          <v-btn
              v-if="items.length > 0 && can('ROLE_DSN_DOWNLOAD_EXCEL')"
              class="ml-5 mr-5"
              @click="exportToExcel"
          >
            <v-icon class="mr-2">mdi-file-excel</v-icon>
            Download Tabelle
            <span v-show="selectedAddresses.length > 0">
              (Nur Auswahl)
            </span>
          </v-btn>
          <address-bulk-edit-modal
              v-if="
              selectedAddresses.length > 0 &&
                !readOnly &&
                can('ROLE_DSN_WRITE_BULK_EDIT')
            "
              :addresses="selectedAddresses"
              @close="selectedAddresses = []"
          />
        </v-col>
        <v-spacer/>
        <v-col cols="auto">
          <v-text-field
              v-model="searchValue"
              append-icon="mdi-magnify"
              label="Suchen"
              clearable
              single-line
              hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
        class="shadow-sm"
        v-model="selectedAddresses"
        :headers="headers"
        :items="filteredAddresses"
        :item-key="itemKey"
        loading-text="Keine Adressen gefunden"
        hide-default-footer
        :page.sync="tableControls.pagination.currentPage"
        :items-per-page="tableControls.pagination.itemsPerPage"
        multi-sort
        show-select
        @page-count="tableControls.pagination.pageCount = $event"
        @toggle-select-all="selectAll"
    >
      <template v-slot:item.address.personId="{ item }">
        <v-edit-dialog
            :return-value="item.address.personId"
            :ref="'edit-dialog-sales-person-' + item.address.id"
        >
          <sales-person-avatar
              :sales-person="getSalesPerson(item.address.personId)"
              :size="30"
              color="secondary"
          />
          {{ getSalesPersonName(item.address.personId) }}
          <template v-slot:input>
            <sales-person-auto-complete
                v-if="!readOnly && can('ROLE_DSN_WRITE_SALES_PERSON')"
                v-model="item.address.personId"
                @change="saveSalesPerson(item)"
                class="mb-6"
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.address.housingIndustry="{ item }">
        <span v-if="item.address.housingIndustry">
          {{ item.address.housingIndustry.label }}
        </span>
        <span v-else>Nicht Zugewiesen</span>
      </template>

      <template
          v-for="(statusGroup, index) in statusGroups"
          v-slot:[getStatusGroupSlotName(statusGroup.value.key)]="{ item }"
      >
        <v-edit-dialog
            v-if="statusGroup.value.key !== 'product'"
            :key="index"
            :return-value="item.status[statusGroup.value.key]"
            :ref="getEditDialogRef(statusGroup.value.key, item.address.id)"
        >
          <status-chip
              :status="item.status[statusGroup.value.key]"
              :status-group="statusGroup.value.key"
          />
          <template v-slot:input>
            <status-select
                v-if="!readOnly && getStatusGroupWritePermission(statusGroup)"
                v-model="item.status[statusGroup.value.key]"
                :status-group="statusGroup"
                @change="saveStatusGroup(item, statusGroup.value.key)"
                class="mb-6"
            />
          </template>
        </v-edit-dialog>
        <status-product-chips v-else :key="index" :location="item"/>
      </template>

      <template v-slot:footer>
        <data-table-footer-controls
            :item-count="filteredAddresses.length"
            v-model="tableControls"
        />
      </template>
      
    </v-data-table>
  </div>
</template>

<script>
import {json2excel} from "js2excel";
import i18nDiclinaService from "../../../../core/services/diclina_i18n";
import StatusSelect from "../../../components/StatusSelect";
import StatusChip from "../../../components/StatusChip";
import SalesPersonAvatar from "../../../components/SalesPersonAvatar";
import StatusProductChips from "../../../components/StatusProductChips";
import AddressBulkEditModal from "../../../components/AddressBulkEditModal";
import SalesPersonAutoComplete from "../../../components/SalesPersonAutoComplete";
import DataTableFooterControls from "../../../components/DataTableFooterControls";

export default {
  name: "AddressDataTable",
  components: {
    DataTableFooterControls,
    SalesPersonAutoComplete,
    AddressBulkEditModal,
    StatusProductChips,
    SalesPersonAvatar,
    StatusChip,
    StatusSelect
  },
  props: ["headers", "itemKey", "loading"],
  data() {
    return {
      search: "",
      searchValue: "",
      awaitingSearch: false,
      searchTimeout: null,
      selectedAddresses: [],
      filteredAddresses: [],
      tableControls: {
        pagination: {
          itemsPerPage: 25,
          currentPage: 1,
          pageCount: 0,
          itemsPerPageOptions: [25, 50, 100, 200, 500]
        }
      }
    };
  },

  computed: {
    statusGroups() {
      return this.$store.getters.statusGroups;
    },

    items() {
      return this.$store.getters.visibleAddresses() || [];
    },

    readOnly() {
      return process.env.VUE_APP_READ_ONLY === "true";
    },
  },
  methods: {
    exportToExcel() {
      let data = this.exportArray();

      json2excel({
        data,
        name: "Adressdaten",
        formatDate: "dd.mm.yyyy"
      });
    },

    exportArray() {
      let exportArray = [];

      let itemArray =
          this.selectedAddresses.length > 0 ? this.selectedAddresses : this.items;

      for (let i = 0; i < itemArray.length; i++) {
        let currRow = itemArray[i];
        exportArray[i] = [];

        for (let j = 0; j < this.headers.length; j++) {
          let currHeader = this.headers[j];

          if (
              !currHeader.value.startsWith("status.") &&
              currHeader.value !== "address.personId"
          ) {
            exportArray[i][currHeader.text] = this.lodash.get(
                currRow,
                currHeader.value
            );
          } else if (currHeader.value === "address.personId") {
            exportArray[i][currHeader.text] = this.getSalesPersonName(
                currRow.address.personId
            );
          }
        }
        const statusGroups = this.statusGroups.filter(statusGroup => {
          return statusGroup.value.key !== "product";
        });

        for (const statusGroup of statusGroups) {
          const statusGroupName =
              i18nDiclinaService.getDiclinaStatusGroupName(
                  statusGroup.value.key
              ) + " Status";

          exportArray[i][
              statusGroupName
              ] = i18nDiclinaService.getDiclinaStatusName(
              statusGroup.value.key,
              currRow.status[statusGroup.value.key]
          );
        }

        const statusGroupName = i18nDiclinaService.getDiclinaStatusGroupName(
            "product"
        );

        const productStatus = [
          "open",
          "opened",
          "accepted",
          "active",
          "rejected"
        ];

        for (let j = 0; j < productStatus.length; j++) {
          const statusName = i18nDiclinaService.getDiclinaStatusName(
              "product",
              productStatus[j]
          );

          exportArray[i][
          statusGroupName + " " + statusName
              ] = this.getCustomerCountByProductStatus(currRow, productStatus[j]);
        }
      }

      return exportArray;
    },

    setSearch(value) {
      this.search = value;
    },

    getStatusName(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusName(statusGroup, status);
    },

    getStatusGroupSlotName(statusGroup) { 
      return "item.status." + statusGroup;
    },
    getStatusGroupWritePermission(statusGroup) {
      return this.$authorizationService.can(i18nDiclinaService.getDiclinaEditPermission(statusGroup.value.key))
    },

    getStatusColor(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusStyle(statusGroup, status)?.color || "";
    },

    getSalesPerson(salesPersonId) {
      return this.$store.getters.salesPersonById(salesPersonId);
    },

    getSalesPersonName(salesPersonId) {
      const salesPerson = this.getSalesPerson(salesPersonId);
      if (salesPerson) {
        return salesPerson.firstName + " " + salesPerson.lastName;
      } else {
        return "Unbekannt";
      }
    },

    saveStatusGroup(item, statusGroup) {
      let updatedAddress = {
        address: {
          id: item.address.id
        },
        status: {}
      };
      updatedAddress.status[statusGroup] = item.status[statusGroup];

      this.$store.dispatch("bulkUpdateAddresses", [updatedAddress]);
      this.$refs[
          this.getEditDialogRef(statusGroup, item.address.id)
          ][0].cancel();
    },

    saveSalesPerson(item) {
      if (item.address.personId || item.address.personId === 0) {
        let updatedAddress = {
          address: {
            id: item.address.id,
            personId: item.address.personId
          }
        };

        this.$store.dispatch("bulkUpdateAddresses", [updatedAddress]);
        this.$refs["edit-dialog-sales-person-" + item.address.id].cancel();
      }
    },

    getEditDialogRef(statusGroup, addressId) {
      return "edit-dialog-" + statusGroup + "-" + addressId;
    },

    selectAll(params) {
        this.selectedAddresses = params.value ? this.filteredAddresses : [];
    },
    getAmountHouseHolds(location) {
      return (
          location.info.amountOfDwellingUnits +
          location.info.amountOfBusinessUnits
      );
    },
    filterAddresses() {
        if (!this.searchValue) {
            this.filteredAddresses = this.items;
        } else {
            this.filteredAddresses = this.items.filter((item) => {
                return item.address.city.toLowerCase().includes(this.searchValue.toLowerCase()) || item.address.street.toLowerCase().includes(this.searchValue.toLowerCase()) || item.address.zipCode.toLowerCase().includes(this.searchValue.toLowerCase())
            })
        }
    },
    getCustomerCountByProductStatus(location, status) {
      const customers = location.customers || [];

      if (status === "open") {
        return this.getAmountHouseHolds(location) - customers.length;
      } else {
        return customers.filter(item => {
          return item.status.product === status;
        }).length;
      }
    }
  },

  watch: {
    searchValue() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search = this.searchValue;
          this.filterAddresses()
      }, 500); // 0.5 sec delay
    },

    selectedAddresses() {
      this.$emit("changedSelectedAddresses", this.selectedAddresses);
    },
    items() {
        this.filterAddresses()
    },
  },
};
</script>

<style scoped></style>
