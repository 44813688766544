<template>
  <div>
    <!-- not a fan of this button being colored, would like a grey -->
    <b-button variant="outline-info" class="mb-3 v-btn" @click="saveFilter">
      <span class="fas fa-sync-alt mr-1"></span>
      Filter Anwenden
    </b-button>
    <div class="row">
      <div class="col-md-12 col-sm-4 col-12 mb-sm-0" style="display: none">
        <b-form-group label="Map Ansicht">
          <b-form-radio-group
            class="w-100"
            v-model="currentMapView"
            :options="mapViews"
            buttons
            button-variant="outline-secondary"
            stacked
          />
        </b-form-group>
      </div>
      <div
        v-for="statusGroup in statusGroups"
        :key="statusGroup.value.key"
        class="col-md-12 col-sm col-12 mb-0"
      >
        <status-auto-complete
          v-if="statusGroup.value.key !== 'product'"
          v-model="statusFilter[statusGroup.value.key]"
          :status-group="statusGroup"
          multiple
          @change="emitChange"
        />
        <status-auto-complete
          v-else
          v-model="statusFilter[statusGroup.value.key]"
          :status-group="statusGroup"
          @change="emitChange"
        />
      </div>
      <div class="col-md-12 col-sm col-12 mb-0">
        <sales-person-auto-complete
          v-model="salesPersonsFilter"
          multiple
          @change="emitChange"
        />
      </div>
      <div class="col-md-12 col-sm col-12 mb-0">
        <housing-industry-auto-complete
          v-model="housingIndustryFilter"
          multiple
          clearable
          @change="emitChange"
        />
      </div>
      <div class="col-md-12 col-sm col-12 mb-md-8 mb-0">
        <h5 class="font-weight-normal text-muted">Anzahl Wohneinheiten</h5>
        <dwelling-unit-range-slider
          v-model="dwellingUnitsFilter.range"
          :rangeLimits="dwellingUnitsFilter.rangeLimits"
          @change="emitChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18nDiclinaService from "../../../../core/services/diclina_i18n";
import StatusAutoComplete from "../../../components/StatusAutoComplete";
import SalesPersonAutoComplete from "../../../components/SalesPersonAutoComplete";
import DwellingUnitRangeSlider from "../../../components/DwellingUnitRangeSlider";
import HousingIndustryAutoComplete from "@/view/components/HousingIndustryAutoComplete";

export default {
  name: "MapFilter",
  components: {
    HousingIndustryAutoComplete,
    DwellingUnitRangeSlider,
    SalesPersonAutoComplete,
    StatusAutoComplete
  },
  props: ["mapViews"],
  data() {
    return {
      currentMapView: "marker",
      activeStatusArray: [],
      selectedStatusGroup: null,
      item: null,
      statusFilter: {},
      salesPersonsFilter: [],
      housingIndustryFilter: [],
      dwellingUnitsFilter: {
        rangeLimits: [0, 100],
        range: [0, 100]
      }
    };
  },

  computed: {
    loadedAddressesCount() {
      return this.$store.getters.loadedAddressesCount;
    },
    statusGroups() {
      return this.$store.getters.statusGroups;
    },
    statusFilterActive() {
      for (const statusGroup of Object.entries(this.statusFilter)) {
        if (statusGroup[0] === "product") {
          if (statusGroup[1]) {
            return true;
          }
        } else {
          if (statusGroup[1].length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    dwellingUnitsFilterActive() {
      return (
        this.dwellingUnitsFilter.range[0] >
          this.dwellingUnitsFilter.rangeLimits[0] ||
        this.dwellingUnitsFilter.range[1] <
          this.dwellingUnitsFilter.rangeLimits[1]
      );
    },
    filterActive() {
      return (
        this.statusFilterActive ||
        this.salesPersonsFilter.length > 0 ||
        this.dwellingUnitsFilterActive
      );
    }
  },

  methods: {
    changeMapView(value) {
      this.$emit("changeMapView", value);
    },

    refreshMap() {
      this.$emit("refreshMap", true);
    },

    saveFilter() {
      this.$store.dispatch(
        "setStatusFilter",
        JSON.parse(JSON.stringify(this.statusFilter))
      );

      this.$store.dispatch("setSalesPersonsFilter", this.salesPersonsFilter);
      this.$store.dispatch("setDwellingUnitsFilter", this.dwellingUnitsFilter);
      this.$store.dispatch(
        "setHousingIndustryFilter",
        this.housingIndustryFilter
      );

      this.refreshMap();
    },

    emptyFilter() {
      this.$store.dispatch("setStatusFilter", null);
      this.$store.dispatch("setSalesPersonsFilter", null);
      this.$store.dispatch("setDwellingUnitsFilter", null);
      this.$store.dispatch("setHousingIndustryFilter", null);

      this.refreshMap();
    },

    getStatusGroupName(statusGroup) {
      return i18nDiclinaService.getDiclinaStatusGroupName(statusGroup);
    },

    emitChange() {
      this.$emit("change", this.filterActive);
    }
  }
};
</script>

<style scoped></style>
