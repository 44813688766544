<template>
  <v-avatar
    :left="left"
    class="mr-3 font-weight-bold"
    :class="bgColor"
    :size="size"
  >
    <v-icon v-if="notAssigned">mdi-account-remove-outline</v-icon>
    <span v-else>{{ salesPersonInitials }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: "SalesPersonAvatar",
  props: {
    left: {
      type: Boolean,
      default: false
    },
    salesPerson: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      required: false
    },
    size: {
      type: Number,
      required: false
    }
  },
  computed: {
    salesPersonInitials() {
      if (this.salesPerson) {
        return (
          this.salesPerson.firstName.substring(0, 1) +
          this.salesPerson.lastName.substring(0, 1)
        ).toUpperCase();
      } else {
        return "?";
      }
    },
    bgColor() {
      return this.color ? "bg-" + this.color : "";
    },
    notAssigned() {
      return !!this.salesPerson ?? this.salesPerson.id === 0;
    }
  }
};
</script>

<style scoped></style>
