<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <b-btn
          v-if="drawingNewPolygon"
          @click="abortDrawingNewPolygon"
          variant="danger"
          class="v-btn"
        >
          <v-icon>mdi-close</v-icon>
          Zeichnen Abbrechen
        </b-btn>
        <b-btn v-else class="v-btn" @click="drawNewPolygon">
          <v-icon>mdi-draw</v-icon>
          Neues Polygon zeichnen
        </b-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-alert v-show="drawingNewPolygon" text icon="mdi-information-outline">
          <span class="ml-4">
            Bitte klicken Sie in die Karte um ein neues Polygon zu zeichnen.
          </span>
        </v-alert>
      </v-col>
    </v-row>

    <map-tool-selection-list
      :items="polygons"
      :loading="loadingNewPolygon"
      v-model="activePolygonIndex"
      @change="viewPolygon"
      @remove="removePolygon"
    />
  </div>
</template>

<script>
import ApiService from "../../../../../core/services/api.service";
import AddressFilterService from "../../../../../core/services/addressfilter.service";
import MapToolSelectionList from "../../../../components/MapToolSelectionList";

export default {
  name: "ToolSettingsPolygon",
  components: { MapToolSelectionList },
  data() {
    return {
      polygons: [],
      activePolygonIndex: null,
      loadingNewPolygon: false,
      drawingNewPolygon: false
    };
  },
  computed: {
    activePolygon() {
      return this.polygons[this.activePolygonIndex];
    },
    addressIds() {
      let addressIds = [];

      for (let i = 0, l = this.polygons.length; i < l; i++) {
        const addresses = this.polygons[i].addresses;

        for (const address of addresses) {
          if (!addressIds.includes(address.address.id)) {
            addressIds.push(address.address.id);
          }
        }
      }

      return addressIds;
    }
  },
  methods: {
    abortDrawingNewPolygon() {
      this.drawingNewPolygon = false;
      this.$root.$emit("abortDrawingNewPolygon");
    },

    drawNewPolygon() {
      this.drawingNewPolygon = true;
      this.$root.$emit("drawNewPolygon");
    },

    async addPolygon(polygon) {
      this.drawingNewPolygon = false;
      let route = [];
      let count = 0;
      let addresses = [];

      this.loadingNewPolygon = true;

      polygon.getPaths().forEach(p => {
        p.getArray().forEach(pt => {
          route.push({ lat: pt.lat(), lng: pt.lng() });
        });
      });

      await ApiService.post("addresses/by-route", {
        route
      }).then(function(response) {
        count = response.data.count;
        addresses = response.data.items;
      });

      this.polygons.push({ count, polygon, addresses });
      this.loadingNewPolygon = false;
    },

    viewPolygon() {
      for (let i = 0, l = this.polygons.length; i < l; i++) {
        const polygon = this.polygons[i].polygon;

        polygon.setOptions({
          fillColor: "#000000",
          strokeColor: "#000000",
          editable: false
        });
      }

      const activePolygon = this.activePolygon;

      if (activePolygon) {
        activePolygon.polygon.setOptions({
          fillColor: "#0000ff",
          strokeColor: "#0000ff",
          editable: false
        });
      }
    },

    removeAllPolygons() {
      for (let i = 0, l = this.polygons.length; i < l; i++) {
        const polygon = this.polygons[i].polygon;

        polygon.setMap(null);
      }

      this.$store.dispatch("setMapToolFilter", null);
    },

    removePolygon(polygon) {
      polygon.polygon.setMap(null);
      this.polygons.splice(this.polygons.indexOf(polygon), 1);
    },

    getPolygonInfos(polygon) {
      const addresses = polygon.addresses;
      let countAfterFilter = 0;
      let streetNames = {};
      let zipCodes = [];

      for (const address of addresses) {
        const streetName = address.address.street;
        const zipCode = address.address.zipCode;

        if (typeof streetNames[streetName] === "undefined") {
          streetNames[streetName] = 0;
        }

        if (!zipCodes.includes(zipCode)) {
          zipCodes.push(zipCode);
        }

        streetNames[streetName] = streetNames[streetName] + 1;

        if (AddressFilterService.filterAddress(address)) {
          countAfterFilter++;
        }
      }

      return {
        countAfterFilter,
        streetNames,
        zipCodes
      };
    }
  },
  watch: {
    addressIds() {
      if (this.polygons.length === 0) {
        this.$store.dispatch("setMapToolFilter", null);
      } else {
        this.$store.dispatch("setMapToolFilter", this.addressIds);
      }

      this.$emit("change", this.polygons.length > 0);
    }
  },
  created() {
    this.$root.$on("addedPolygon", this.addPolygon);
  },
  beforeDestroy() {
    this.removeAllPolygons();
  }
};
</script>

<style scoped></style>
